import { SnackbarOrigin } from "@material-ui/core";
import { AlertColor } from "@mui/material";
import * as React from "react";
import BrokerResponse from "../api/request/Broker/Model/Response/BrokerResponse.model";
import useCache from "../hooks/useCache";

export interface Notification {
  anchorOrigin?: SnackbarOrigin;
  autoHideDuration?: number;
  type: AlertColor;
  message: string;
  link?: React.ReactNode;
  status: boolean;
}

export interface IncidentModal {
  open: boolean;
  leadId: string
}

interface ContextGetlifeProps {
  DOMAIN: string;
  broker: BrokerResponse | undefined;
  brokerId: string;
  carrierId: string;
  email: string;
  issueCreated: boolean;
  leadId: string;
  loading: boolean;
  newIncidentModal: IncidentModal;
  question: string;
  rol: any;
  setBroker: (broker: BrokerResponse) => void;
  setBrokerId: (brokerId: string) => void;
  setCarrierId: (carrierId: string) => void;
  setEmail: (email: string) => void;
  setIssueCreated: (issueCreated: boolean) => void;
  setLeadId: (leadId: string) => void;
  setLoading: (loading: boolean) => void;
  setNewIncidentModal: (newIncidentModal: IncidentModal) => void;
  setQuestion: (value: {}) => void;
  setRol: (rol: any) => void;
  setStatus: (status: string) => void;
  setStatusEmployee: (statusEmployee: string) => void;
  setShowNotification: (notification: Notification | boolean) => void;
  setToken: (token: string) => void;
  setTokenVelogica: (value: string) => void;
  status: string;
  statusEmployee: string;
  showNotification: Notification | boolean;
  token: string;
  tokenVelogica: string;
}

export type LanguageT = "es" | "fr" | "en" | "pt" | "it" | "wl_es" | "sql_es";

export type LocalesStandard = "es" | "fr" | "en" | "pt" | "it";

export const ContextGetlife = React.createContext({} as ContextGetlifeProps);

export const Provider = ({ children }: any) => {
  const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE || "";
  const [broker, setBroker] = React.useState<BrokerResponse>();
  const [brokerId, setBrokerId] = useCache("brokerId");
  const [carrierId, setCarrierId] = useCache("carrierId");
  const [email, setEmail] = React.useState("");
  const [leadId, setLeadId] = useCache("lead");
  const [loading, setLoading] = React.useState(false);
  const [rol, setRol] = React.useState(false);
  const [status, setStatus] = useCache("status");
  const [statusEmployee, setStatusEmployee] = useCache("statusEmployee");
  const [token, setToken] = useCache("token");
  const [tokenVelogica, setTokenVelogica] = useCache("tokenVelogica");
  const [question, setQuestion] = useCache("question");
  const [showNotification, setShowNotification] = React.useState<
    Notification | boolean
  >(false);
  const [newIncidentModal, setNewIncidentModal] = React.useState<IncidentModal>({
    open: false,
    leadId: ""
  });
  const [issueCreated, setIssueCreated] = React.useState<boolean>(false);

  const values = {
    DOMAIN,
    broker,
    brokerId,
    carrierId,
    email,
    issueCreated,
    leadId,
    loading,
    newIncidentModal,
    question,
    rol,
    setBroker,
    setBrokerId,
    setCarrierId,
    setEmail,
    setLeadId,
    setIssueCreated,
    setLoading,
    setNewIncidentModal,
    setQuestion,
    setRol,
    setStatus,
    setStatusEmployee,
    setShowNotification,
    setToken,
    setTokenVelogica,
    status,
    statusEmployee,
    showNotification,
    token,
    tokenVelogica,
  };
  return (
    <ContextGetlife.Provider value={values}>{children}</ContextGetlife.Provider>
  );
};
