import { useContext, useState } from "react";
import { I18nContext } from "../../../contexts/i18n.context";
import {
  ButtonDS,
  GlobalIcon,
  InputDS,
  ModalDS,
  Toggle,
} from "get-life-storybook-ts";
import { Accordion } from "./DashboardMultiproduct";
import { MultiproductContext } from "./MultiproductContext";
import { onEnterBlur } from "../../../utils/onEnterBlur";

const ComplementaryGuarantees = () => {
  const [open, setOpen] = useState(true);
  const [disabilityModal, setDisabilityModal] = useState(false);
  const [temporaryDisabilityModal, setTemporaryDisabilityModal] =
    useState(false);
  const [seriousIllnessModal, setSeriousIllnessModal] = useState(false);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const {
    rules,
    guaranteeForm,
    toggleGuaranteeActive,
    updateGuaranteeCapital,
    SQLLegalNote,
  } = useContext(MultiproductContext);

  const DISABILITY = rules?.find((e) => e.trait === "DISABILITY");
  const TEMPORARY_DISABILITY = rules?.find(
    (e) => e.trait === "TEMPORARY_DISABILITY"
  );
  const SERIOUS_ILLNESS = rules?.find((e) => e.trait === "SERIOUS_ILLNESS");

  const IsIPAActive = guaranteeForm["DISABILITY"].active;
  const IsIPTActive = guaranteeForm["TEMPORARY_DISABILITY"].active;

  if (!rules) return null;

  return (
    <>
      <div className="newDashboard-tariffifier">
        <div className="flex flex-row items-center justify-between">
          <div className="BodyL font-bold flex flex-row items-center gap-[16px] text-[#424242]">
            <GlobalIcon
              iconName="ShieldChevronIcon"
              size="XS"
              color="currentColor"
            />
            {translate("dashboard.multiproduct.additionalGuarantees")}
          </div>
          <button
            className="p-[2px]"
            onClick={() => setOpen(!open)}
            style={{
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
            }}
          >
            <GlobalIcon iconName="ChevronDown" color="currentColor" />
          </button>
        </div>
        <Accordion height={900} open={open}>
          <>
            <div className="grid grid-cols-2 gap-x-[48px] items-center gap-y-[16px] border-b border-b-[#D9D9D9] pb-[24px] mt-[24px]">
              <div className="flex flex-col gap-[10px]">
                <div
                  className="BodyM font-medium text-[#424242] flex flex-row items-center gap-[8px]"
                  style={{
                    color: IsIPTActive ? "#A0A0A0" : "#424242",
                  }}
                >
                  <GlobalIcon
                    iconName="DisabledIcon"
                    size="XS"
                    color={
                      IsIPTActive ? "#A0A0A0" : "var(--dark-primary-color)"
                    }
                  />
                  <span className="mr-auto flex-1">
                    {translate("dashboard.multiproduct.absoluteDisability")}
                  </span>
                  {IsIPTActive ? (
                    <span className="font-light">
                      {translate("edit.sql.project.incompatible")}
                    </span>
                  ) : (
                    <Toggle
                      initialValue={guaranteeForm["DISABILITY"].active}
                      onValueChange={() => toggleGuaranteeActive("DISABILITY")}
                      labels={[]}
                      disabled={
                        !DISABILITY?.available ||
                        guaranteeForm["TEMPORARY_DISABILITY"].active
                      }
                    />
                  )}
                </div>
                <div className="BodyS text-[#8E8E8E] flex flex-row gap-[4px]">
                  <GlobalIcon
                    iconName="InfoIcon"
                    size="XS"
                    className="w-[18px] h-[18px]"
                    color="currentColor"
                  />
                  <p className="flex-1">
                    {translate(
                      "dashboard.multiproduct.absoluteDisability.hint"
                    )}{" "}
                    <button
                      className="font-bold underline"
                      onClick={() => setDisabilityModal(true)}
                    >
                      {translate("dashboard.multiproduct.moreInformation")}
                    </button>
                  </p>
                </div>
              </div>
              <InputDS
                type="number"
                label={translate(
                  "dashboard.multiproduct.absoluteDisability.label"
                )}
                placeholder={"10.000"}
                name="ipaCapital"
                icon="CurrencyEuroIcon"
                onKeyDown={(e) => {
                  if (e.key === "," || e.key === ".") e.preventDefault();
                }}
                helper={`${translate(
                  "dashboard.multiproduct.minimum"
                )} ${new Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                  maximumFractionDigits: 0,
                }).format(DISABILITY?.min!)}, ${translate(
                  "dashboard.multiproduct.maximum"
                )} ${new Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                  maximumFractionDigits: 0,
                }).format(DISABILITY?.max!)}`}
                min={DISABILITY?.min}
                max={DISABILITY?.max}
                step={5000}
                disabled={!guaranteeForm["DISABILITY"].active}
                value={guaranteeForm["DISABILITY"].capital}
                onChange={(e) => {
                  const capital = parseInt(e.target.value);
                  updateGuaranteeCapital("DISABILITY", capital);
                }}
                onBlur={() => {
                  let value = guaranteeForm["DISABILITY"].capital;
                  if (value > DISABILITY?.max!) {
                    value = DISABILITY?.max!;
                  }
                  if (value < DISABILITY?.min!) {
                    value = DISABILITY?.min!;
                  }
                  if (!value) value = DISABILITY?.min!;
                  updateGuaranteeCapital("DISABILITY", value, true);
                }}
                onKeyDownCapture={onEnterBlur}
              />
            </div>

            <div className="grid grid-cols-2 gap-x-[48px] items-center gap-y-[16px] border-b border-b-[#D9D9D9] py-[24px]">
              <div className="flex flex-col gap-[10px]">
                <div
                  className="BodyM font-medium text-[#424242] flex flex-row items-center gap-[8px]"
                  style={{
                    color: IsIPAActive ? "#A0A0A0" : "#424242",
                  }}
                >
                  <GlobalIcon
                    iconName="DisabledIcon"
                    size="XS"
                    color={
                      IsIPAActive ? "#A0A0A0" : "var(--dark-primary-color)"
                    }
                  />
                  <span className="mr-auto flex-1">
                    {translate("dashboard.multiproduct.totalDisability")}
                  </span>
                  {IsIPAActive ? (
                    <span className="font-light">
                      {translate("edit.sql.project.incompatible")}
                    </span>
                  ) : (
                    <Toggle
                      initialValue={
                        guaranteeForm["TEMPORARY_DISABILITY"].active
                      }
                      onValueChange={() =>
                        toggleGuaranteeActive("TEMPORARY_DISABILITY")
                      }
                      labels={[]}
                      disabled={
                        !TEMPORARY_DISABILITY?.available ||
                        guaranteeForm["DISABILITY"].active
                      }
                    />
                  )}
                </div>
                <div className="BodyS text-[#8E8E8E] flex flex-row gap-[4px]">
                  <GlobalIcon
                    iconName="InfoIcon"
                    size="XS"
                    className="w-[18px] h-[18px]"
                    color="currentColor"
                  />
                  <p className="flex-1">
                    {translate("dashboard.multiproduct.totalDisability.hint")}{" "}
                    <button
                      className="font-bold underline"
                      onClick={() => setTemporaryDisabilityModal(true)}
                    >
                      {translate("dashboard.multiproduct.moreInformation")}
                    </button>
                  </p>
                </div>
              </div>
              <InputDS
                type="number"
                label={translate(
                  "dashboard.multiproduct.totalDisability.label"
                )}
                placeholder={"10.000"}
                name="ipaCapital"
                icon="CurrencyEuroIcon"
                onKeyDown={(e) => {
                  if (e.key === "," || e.key === ".") e.preventDefault();
                }}
                helper={`${translate(
                  "dashboard.multiproduct.minimum"
                )} ${new Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                  maximumFractionDigits: 0,
                }).format(TEMPORARY_DISABILITY?.min!)}, ${translate(
                  "dashboard.multiproduct.maximum"
                )} ${new Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                  maximumFractionDigits: 0,
                }).format(TEMPORARY_DISABILITY?.max!)}`}
                min={TEMPORARY_DISABILITY?.min}
                max={TEMPORARY_DISABILITY?.max}
                step={5000}
                disabled={!guaranteeForm["TEMPORARY_DISABILITY"].active}
                value={guaranteeForm["TEMPORARY_DISABILITY"].capital}
                onChange={(e) => {
                  const capital = parseInt(e.target.value);
                  updateGuaranteeCapital("TEMPORARY_DISABILITY", capital);
                }}
                onBlur={() => {
                  let value = guaranteeForm["TEMPORARY_DISABILITY"].capital;
                  if (value > TEMPORARY_DISABILITY?.max!) {
                    value = TEMPORARY_DISABILITY?.max!;
                  }
                  if (value < TEMPORARY_DISABILITY?.min!) {
                    value = TEMPORARY_DISABILITY?.min!;
                  }
                  if (!value) TEMPORARY_DISABILITY?.min!;
                  updateGuaranteeCapital("TEMPORARY_DISABILITY", value, true);
                }}
                onKeyDownCapture={onEnterBlur}
              />
            </div>

            <div className="grid grid-cols-2 gap-x-[48px] items-center gap-y-[16px] pt-[24px]">
              <div className="flex flex-col gap-[10px]">
                <div className="BodyM font-medium text-[#424242] flex flex-row items-center gap-[8px]">
                  <GlobalIcon
                    iconName="WiderUmbrellaIcon"
                    size="XS"
                    color="var(--dark-primary-color)"
                  />
                  <span className="mr-auto flex-1">
                    {translate("dashboard.multiproduct.seriousIllnesses")}
                  </span>
                  <Toggle
                    initialValue={guaranteeForm["SERIOUS_ILLNESS"].active}
                    onValueChange={() =>
                      toggleGuaranteeActive("SERIOUS_ILLNESS")
                    }
                    labels={[]}
                    disabled={!SERIOUS_ILLNESS?.available}
                  />
                </div>
                <div className="BodyS text-[#8E8E8E] flex flex-row gap-[4px]">
                  <GlobalIcon
                    iconName="InfoIcon"
                    size="XS"
                    className="w-[18px] h-[18px]"
                    color="currentColor"
                  />
                  <p className="flex-1">
                    {translate("dashboard.multiproduct.seriousIllnesses.hint")}{" "}
                    <button
                      className="font-bold underline"
                      onClick={() => setSeriousIllnessModal(true)}
                    >
                      {translate("dashboard.multiproduct.moreInformation")}
                    </button>
                  </p>
                </div>
              </div>
              <InputDS
                type="number"
                label={translate(
                  "dashboard.multiproduct.seriousIllnesses.label"
                )}
                placeholder={"10.000"}
                name="ipaCapital"
                icon="CurrencyEuroIcon"
                onKeyDown={(e) => {
                  if (e.key === "," || e.key === ".") e.preventDefault();
                }}
                helper={`${translate(
                  "dashboard.multiproduct.minimum"
                )} ${new Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                  maximumFractionDigits: 0,
                }).format(SERIOUS_ILLNESS?.min!)}, ${translate(
                  "dashboard.multiproduct.maximum"
                )} ${new Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                  maximumFractionDigits: 0,
                }).format(SERIOUS_ILLNESS?.max!)}. ${translate(
                  "dashboard.multiproduct.seriousIllnesses.helper"
                )}`}
                min={SERIOUS_ILLNESS?.min}
                max={SERIOUS_ILLNESS?.max}
                step={5000}
                disabled={!guaranteeForm["SERIOUS_ILLNESS"].active}
                value={guaranteeForm["SERIOUS_ILLNESS"].capital}
                onChange={(e) => {
                  const capital = parseInt(e.target.value);
                  updateGuaranteeCapital("SERIOUS_ILLNESS", capital);
                }}
                onBlur={() => {
                  let value = guaranteeForm["SERIOUS_ILLNESS"].capital;
                  if (value > SERIOUS_ILLNESS?.max!) {
                    value = SERIOUS_ILLNESS?.max!;
                  }
                  if (value < SERIOUS_ILLNESS?.min!) {
                    value = SERIOUS_ILLNESS?.min!;
                  }
                  if (!value) value = SERIOUS_ILLNESS?.min!;
                  updateGuaranteeCapital("SERIOUS_ILLNESS", value, true);
                }}
                onKeyDownCapture={onEnterBlur}
              />
            </div>
          </>
        </Accordion>
      </div>
      <ModalDS
        onClose={() => setDisabilityModal(false)}
        open={disabilityModal}
        title={translate("dashboard.multiproduct.modal.title.disability")}
        icon="InfoIcon"
        content={
          <div
            className="text-[#555555]"
            dangerouslySetInnerHTML={{
              __html: translate(
                "dashboard.multiproduct.modal.content.disability"
              ),
            }}
          />
        }
        buttons={
          <div>
            <ButtonDS
              label={translate("dashboard.multiproduct.modal.download")}
              buttonType="tertiary"
              className="w-fit ml-auto"
              href={SQLLegalNote}
              target="_blank"
            />
          </div>
        }
      />
      <ModalDS
        onClose={() => setTemporaryDisabilityModal(false)}
        open={temporaryDisabilityModal}
        title={translate("dashboard.multiproduct.modal.title.totalDisability")}
        icon="InfoIcon"
        content={
          <div
            className="text-[#555555]"
            dangerouslySetInnerHTML={{
              __html: translate(
                "dashboard.multiproduct.modal.content.totalDisability"
              ),
            }}
          />
        }
        buttons={
          <div>
            <ButtonDS
              label={translate("dashboard.multiproduct.modal.download")}
              buttonType="tertiary"
              className="w-fit ml-auto"
              href={SQLLegalNote}
              target="_blank"
            />
          </div>
        }
      />
      <ModalDS
        onClose={() => setSeriousIllnessModal(false)}
        open={seriousIllnessModal}
        title={translate("dashboard.multiproduct.modal.title.seriousIllness")}
        icon="InfoIcon"
        content={
          <div
            className="text-[#555555]"
            dangerouslySetInnerHTML={{
              __html: translate(
                "dashboard.multiproduct.modal.content.seriousIllness"
              ),
            }}
          />
        }
        buttons={
          <div>
            <ButtonDS
              label={translate("dashboard.multiproduct.modal.download")}
              buttonType="tertiary"
              className="w-fit ml-auto"
              href={SQLLegalNote}
              target="_blank"
            />
          </div>
        }
      />
    </>
  );
};

export default ComplementaryGuarantees;
