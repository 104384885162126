import { useContext, useEffect, useState } from "react";
import "./calendarGetlife.scss";

import moment from "moment";
import { DialogContent } from "@material-ui/core";
import SelectGetlife from "../select/SelectGetlife";
import { ButtonDS, Calendar, ModalDS, SelectDS } from "get-life-storybook-ts";
import { I18nContext } from "../../../contexts/i18n.context";
import {
  DayAvailable,
  DaysAvailable,
  GlobalLocaleCalendar,
} from "../../../utils/InternationlChanges";

const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

export default function CalendarExtendedGetlife(props) {
  const [finalValue, setFinalValue] = useState(props.answer);
  const [selectedDay, setSelectedDay] = useState(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(props.answer);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const handleCalendarClose = () => {
    setCalendarOpen(false);
  };

  useEffect(() => {
    if (selectedDay !== null) {
      setFinalValue(
        `${selectedDay.getFullYear()}-${
          selectedDay.getMonth() + 1
        }-${selectedDay.getDate()}`
      );
      setSelectedOption("other");
    }
  }, [selectedDay]);

  useEffect(() => {
    if (finalValue !== null) props.handleValues(finalValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalValue]);

  const today = moment();
  const todayDate = new Date(
    new Date().setDate(new Date().getDate() + DayAvailable[DOMAIN])
  );
  const finalDate = new Date().setDate(
    new Date().getDate() + DaysAvailable[DOMAIN]
  );
  const tomorrow = moment().add(1, "days");
  const nextWeek = moment().add(7, "days");
  const fifteenDays = moment().add(15, "days");
  const oneMonth = moment().add(1, "months");

  const handleRadios = (e) => {
    const value = e.target?.value;
    if (value === "other") {
      setCalendarOpen(true);
    } else {
      setSelectedOption(value);
      setFinalValue(value);
    }
  };

  const optionsDateList = () => {
    if (DOMAIN === "it") {
      return [
        {
          value: tomorrow.format("YYYY-MM-DD"),
          label: translate("question.calendar.tomorrow"),
        },
        {
          value: nextWeek.format("YYYY-MM-DD"),
          label: translate("question.calendar.oneWeek"),
        },
        {
          value: fifteenDays.format("YYYY-MM-DD"),
          label: translate("question.calendar.twoWeek"),
        },
        {
          value: "other",
          label: translate("question.calendar.otherDay"),
        },
      ];
    } else {
      return [
        {
          label: translate("question.calendar.today"),
          value: today.format("YYYY-MM-DD"),
        },
        {
          value: tomorrow.format("YYYY-MM-DD"),
          label: translate("question.calendar.tomorrow"),
        },
        {
          value: nextWeek.format("YYYY-MM-DD"),
          label: translate("question.calendar.oneWeek"),
        },
        {
          value: fifteenDays.format("YYYY-MM-DD"),
          label: translate("question.calendar.twoWeek"),
        },
        {
          value: oneMonth.format("YYYY-MM-DD"),
          label: translate("question.calendar.month"),
        },
        {
          value: "other",
          label: translate("question.calendar.otherDay"),
        },
      ];
    }
  };

  return (
    <div className={"calendar_extended_container w-full"}>
      <SelectDS
        disabled={props.disabled}
        value={selectedOption}
        options={optionsDateList()}
        placeholder={translate("question.calendar.placeholder")}
        onChange={handleRadios}
      />
      <p>
        {translate("question.calendar.subtext")}{" "}
        <strong>
          {finalValue !== null &&
            finalValue !== "" &&
            finalValue !== false &&
            moment(finalValue).format("DD-MM-YYYY")}
        </strong>
      </p>
      <ModalDS
        open={calendarOpen}
        onClose={handleCalendarClose}
        title={translate("question.page.changeDate")}
        icon="HelpIcon"
        content={
          <div className="mx-auto max-w-[315px]">
            <Calendar
              date={selectedDay}
              setDate={setSelectedDay}
              maxDate={new Date(finalDate)}
              minDate={todayDate}
              locale={GlobalLocaleCalendar[DOMAIN]}
            />
          </div>
        }
        buttons={
          <>
            <ButtonDS
              onClick={handleCalendarClose}
              label={translate("question.page.cancel")}
              buttonType="secondary"
            />
            <ButtonDS
              onClick={() => {
                setFinalValue(
                  `${selectedDay.getFullYear()}-${
                    selectedDay.getMonth() + 1
                  }-${selectedDay.getDate()}`
                );
                setSelectedOption("other");
                handleCalendarClose();
              }}
              label={translate("question.page.continue")}
              disabled={!selectedDay}
            />
          </>
        }
      />
    </div>
  );
}
